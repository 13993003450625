
























import { Vue, Component } from "vue-property-decorator";

@Component
export default class Clients extends Vue {
  slide = null;

  items = [
    {
      logo: require("@/assets/client-acb.svg"),
      name: "T.C. Aile, Çalışma ve Sosyal Hizmetler Bakanlığı"
    },
    {
      logo: require("@/assets/client-tb.svg"),
      name: "T.C. Ticaret Bakanlığı"
    },
    {
      logo: require("@/assets/client-tab.svg"),
      name: "TAB Gıda Sanayi ve Ticaret A.Ş."
    },
    {
      logo: require("@/assets/client-acb.svg"),
      name: "T.C. Aile, Çalışma ve Sosyal Hizmetler Bakanlığı"
    },
    {
      logo: require("@/assets/client-tb.svg"),
      name: "T.C. Ticaret Bakanlığı"
    }
  ];
}
