



















import { Vue, Component } from "vue-property-decorator";

@Component
export default class Banner extends Vue {
  carousel = 0;

  banners = [
      {
      subtitle: `Adalet Bakanlığı ve HEGEM Vakfı`,
      title: "Yeminli Sosyal Arabuluculuk Sertifika Programı",
      text: "Adalet Bakanlığı, Şiddetle Mücadele Vakfı (HEGEM Vakfı) ortaklığı ile yürütülen <em>\"İnsan Hakları, Şiddetle Sivil Mücadele ve Sosyal Arabuluculuk Bilincini Güçlendirici Saha Aktörleri Yetiştirilmesi Projesi\"</em> kapsamında hazırlanan Yeminli Sosyal Arabuluculuk Sertifika Programı'na kayıtlar başlamıştır.",
      img: require("@/assets/banner-yeminli-sosyal-arabuluculuk.png"),
      btn: {
        text: "İNCELE",
        href: "/program-katalogu/hegem-yeminli-sosyal-arabuluculuk"
      }
    },
    {
      subtitle: `Adalet Bakanlığı ve HEGEM Vakfı`,
      title: "Şiddeti Önleme ve İnsan Hakları Danışmanlığı Sertifika Programı",
      text: "Adalet Bakanlığı, Şiddetle Mücadele Vakfı (HEGEM Vakfı) ortaklığı ile yürütülen <em>\"İnsan Hakları, Şiddetle Sivil Mücadele ve Sosyal Arabuluculuk Bilincini Güçlendirici Saha Aktörleri Yetiştirilmesi Projesi\"</em> kapsamında hazırlanan Şiddeti Önleme ve İnsan Hakları Danışmanlığı Sertifika Programı'na kayıtlar başlamıştır.",
      img: require("@/assets/banner-siddeti-onleme-ve-insan-haklari-danismanligi.png"),
      btn: {
        text: "İNCELE",
        href: "/program-katalogu/hegem-siddeti-onleme-ve-insan-haklari-danismanligi"
      }
    },
    {
      subtitle: `Türkiye'de ilk defa`,
      title: "Aile Temelli Arabuluculuk Sertifika Programı",
      text: "Adalet Bakanlığı, Şiddetle Mücadele Vakfı ortaklığı ve İçişleri Bakanlığı desteği ile hazırlanan bu sertifika programı, Anadolu Üniversitesi ile HEGEM Vakfı arasında imzalanan protokol kapsamında tarafımızca yürütülmektedir.",
      img: require("@/assets/banner-aile-temelli-arabuluculuk.png"),
      btn: {
        text: "İNCELE",
        href: "/program-katalogu/hegem-aile-temelli-arabuluculuk"
      }
    }
    // {
    //   subtitle: `T.C. Ticaret Bakanlığı Onaylı`,
    //   title: "Kooperatifçilik Eğitim Programı",
    //   text: "Anadolu Üniversitesi ile Ticaret Bakanlığı arasında imzalanan Kooperatifçilik Eğitimi Yetkilendirme Protokolü’ne göre Üniversitemiz Sürekli Eğitim Uygulama ve Araştırma Merkezi (ANADOLUSEM) Kooperatifçilik eğitimlerini düzenlemek üzere Ticaret Bakanlığı tarafından Eğitim Sağlayıcı olarak yetkilendirilmiştir.",
    //   img: require("@/assets/banner-kooperatifcilik-egitimi.png"),
    //   btn: {
    //     text: "İNCELE",
    //     href: "/program-katalogu/kooperatifcilik"
    //   }
    // },
    // {
    //   subtitle: `Düzenli Aralıkla Zenginleştirilecek Olan`,
    //   title: "Yeni Eğitim Kataloğumuz Yayınlandı",
    //   text: "Farklı yaşam boyu öğrenme ihtiyaçlarınıza etkin çözümler sunmak amacıyla tasarladığımız yeni eğitim kataloğumuz yayınlandı.",
    //   img: require("@/assets/banner-course-catalog.png"),
    //   btn: {
    //     text: "Eğitimleri Keşfet",
    //     href: "/egitim-katalogu"
    //   }
    // },
    // {
    //   subtitle: `Anadolu Üniversitesi Aday Memurlara`,
    //   title: "Hizmet İçi Eğitim Programı Başladı",
    //   text: "Personel Daire Başkanlığı tarafından planlanan ve Sürekli Eğitim Merkezi (ANADOLUSEM) aracılığıyla yürütülecek olan Aday Memurların Yetiştirilmelerine Yönelik Hizmet İçi Eğitim Programı başladı.",
    //   img: require("@/assets/banner-aday-memur-egitimi.png"),
    //   btn: {
    //     text: "Detaylar",
    //     href: "http://egazete.anadolu.edu.tr/kampus/40279/anadolu-universitesi-aday-memurlara-hizmet-ici-egitim-programi-basladi"
    //   }
    // },
    // {
    //   subtitle: `Devlet Konservatuvarı Müzik ve Bale Ortaokulu Yarı Zamanlı Sertifika Programı`,
    //   title: "Koro Sınavı Sonuçları Açıklandı",
    //   text: "2021-2022 öğretim yılı yarı zamanlı koro programı sınav sonuçları açıklandı.",
    //   img: require("@/assets/banner-muzik-ve-bale-ortaokulu.png"),
    //   btn: {
    //     text: "Detaylar",
    //     href: "/egitim-katalogu/muzik-ve-bale-ortaokulu"
    //   }
    // }
    // {
    //   subtitle: `Uluslararası Öğrencilere Yönelik`,
    //   title: "İngilizce Hazırlık Eğitimi",
    //   text: "2021-2022 öğretim yılında lisansüstü programlara kabul edilen uluslararası öğrencilere yönelik İngilizce Hazırlık Eğitimi'ne kayıtlar başladı.",
    //   img: require("@/assets/banner-ingilizce-hazirlik.png"),
    //   btn: {
    //     text: "Detaylar",
    //     href: "/egitim-katalogu/ingilizce-hazirlik"
    //   }
    // },
    // {
    //   subtitle: `Devlet Konservatuvarı Müzik ve Bale Ortaokulu Yarı Zamanlı Koro Programı`,
    //   title: "Kayıt Başvuruları Başladı",
    //   text: "2021-2022 öğretim yılı yarı zamanlı koro programına kayıtlar başladı. Başvuru ve kayıt süreci için detaylara göz atınız.",
    //   img: require("@/assets/banner-koro.png"),
    //   btn: {
    //     text: "Detaylar",
    //     href: "/egitim-katalogu/koro"
    //   }
    // },
    // {
    //   subtitle: `Devlet Konservatuvarı Müzik ve Bale Ortaokulu Yarı Zamanlı Sertifika Programı`,
    //   title: "2. Aşama Sınav Sonuçları Açıklandı",
    //   text: "2021-2022 öğretim yılı yarı zamanlı çalgı programı 2. aşama sınav sonuçları açıklandı.",
    //   img: require("@/assets/banner-muzik-ve-bale-ortaokulu.png"),
    //   btn: {
    //     text: "Detaylar",
    //     href: "/egitim-katalogu/muzik-ve-bale-ortaokulu"
    //   }
    // }
    // ,{
    //   subtitle: `Yerel Yönetimler Görevde <br/> Yükselme ve Unvan Değişikliği`,
    //   title: "Sınava Hazırlık Eğitimi Başladı!",
    //   text: "26 Eylül 2021 tarihinde düzenlenecek olan Yerel Yönetimler Görevde Yükselme ve Unvan Değişikliği Sınavına Hazırlık Eğitimi başladı. Eğitim süreci ve takvim için detaylara göz atınız.",
    //   img: require("@/assets/banner-yerel-yonetimler.png"),
    //   btn: {
    //     text: "Detaylar",
    //     href: "/egitim-katalogu/yerel-yonetimler"
    //   }
    // }
    // ,{
    //   subtitle: `Anadolu Üniversitesi <br/> Sürekli Eğitim Merkezi Farkıyla`,
    //   title: "Öğrenmenin keyfini yaşayın!",
    //   text: "Yaşam boyu öğrenme felsefesini kendine misyon edinmiş güçlü bir kadroyla, her türlü öğrenme ihtiyacınıza yanıt verebilecek bireysel ve kurumsal eğitim çözümleri sunuyoruz.",
    //   img: require("@/assets/banner1.png"),
    //   btn: {
    //     text: "Hemen Başla",
    //     href: ""
    //   }
    // }
  ];
}
