










import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";
import Topics from "./Topics.vue";
import Numbers from "./Numbers.vue";
import Quotes from "./Quotes.vue";
import Clients from "./Clients.vue";

@Component({
  components: {
    Banner,
    Topics,
    Numbers,
    Quotes,
    Clients
  }
})
export default class Home extends Vue {}
