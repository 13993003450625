<template>
  <v-container class="numbers">
    <v-row justify="center" align="center" class="flex-column flex-sm-row">
      <h3>148 <span>Program</span></h3>
      <v-divider :vertical="isVertical" class="my-6 my-md-0 mx-sm-12" />
      <h3>876 <span>Eğitim</span></h3>
      <v-divider :vertical="isVertical" class="my-6 my-md-0 mx-sm-12" />
      <h3>2M+ <span>Öğrenci</span></h3>
      <v-divider :vertical="isVertical" class="my-6 my-md-0 mx-sm-12" />
      <h3>%88 <span>Başarı</span></h3>
    </v-row>
  </v-container>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Numbers extends Vue {
  isVertical = this.$vuetify.breakpoint.smAndUp;
}
</script>

<style lang="scss">
.numbers {
  margin-top: 100px;
  margin-bottom: 60px;

  .row {
    h3 {
      font-size: 3rem;
      text-align: center;

      span {
        font-size: 1rem;
        color: $color1;
        display: block;
      }
    }

    .v-divider {
      width: 50%;
    }

    .v-divider--vertical {
      height: 50px;
      align-self: unset;
    }
  }
}
</style>
