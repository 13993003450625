<template>
  <v-container class="pt-12 quotes">
    <v-row class="mb-4">
      <v-col class="text-center">
        <h3 class="accent--text">Bizi Başkalarından Dinleyin</h3>
        <h1>Öğrenci Görüşleri</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-slide-group v-model="slide">
        <v-slide-item v-for="(item, i) in items" :key="i">
          <div class="primary rounded-lg ma-3 pa-6 pt-10 elevation-6 quote">
            <img src="@/assets/quote.svg" alt="" />
            <p class="text-justify">{{ item.text }}</p>
            <div class="d-flex justify-start align-center">
              <img :src="item.img" alt="" />
              <h4>
                {{ item.name }} <span>{{ item.title }}</span>
              </h4>
            </div>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-row>
  </v-container>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Quotes extends Vue {
  slide = null;

  items = [
    {
      name: "Özlem Altuğ",
      title: "Biyoloji Öğretmeni",
      img: require("@/assets/oaltug.jpg"),
      text: "Teknolojinin hayatımıza girmesi ile benim mesleğim olan eğitim alanında da çeşitli gelişmeler oldu ve her geçen gün yenileri eklenmekte. Mesleğimdeki gelişmeleri takip etmek ve kendimi geliştirmek için bu sertifika programına katıldım. Katıldığım program için e-alıştırma ve e-sınav bölümleri sınava hazırlanırken çok..."
    },
    {
      name: "Ebru Ozman",
      title: "Eğiitm Uzmanı",
      img: require("@/assets/eozman.jpg"),
      text: "Kişisel gelişimime katkıda bulunmak amacıyla Anadolu Üniversitesi  e-Sertifika programına katıldım. Bence öğrenmenin yaşı ve sınırı olmadığının bir örneği. Eğer birey kendini disipline edebilir, zaman ayırır ve çalışır ise başaramaması için hiç bir neden yoktur. Ayrıca günümüz çalışma koşulları düşünüldüğünde fiziksel..."
    },
    {
      name: "Caner Balabanuluğ",
      title: "Satış Müdürü",
      img: require("@/assets/cbalabanulug.jpg"),
      text: "Çalıştığım şirkette almış olduğum eğitimlerin yanı sıra akademik bir kurum tarafından verilen teorik bilgileri de edinmek amacıyla Perakendecilik sertifika programına katıldım. Halen perakende sektöründe uluslararası bir firmada yönetici olarak çalışmaktayım. Almış olduğum sertifikanın kariyerim açısından etkisi..."
    }
  ];
}
</script>

<style lang="scss">
.quotes {
  .v-slide-group__content {
    white-space: normal !important;
  }

  .v-slide-group__prev,
  .v-slide-group__next {
    i {
      border: solid 1px $color2;
      border-radius: 50%;
    }
  }

  .quote {
    position: relative;
    width: 330px;

    p {
      font-size: smaller;
      color: #fff;
    }

    & > img {
      display: block;
      position: absolute;
      width: 50px;
      top: -11px;
      right: 20px;
    }

    & > div {
      img {
        height: 50px;
        border-radius: 50%;
      }

      h4 {
        margin-left: 10px;

        span {
          display: block;
          color: #fff;
          font-size: smaller;
        }
      }
    }
  }
}
</style>
