



































import { Vue, Component } from "vue-property-decorator";

@Component
export default class Topics extends Vue {
  slide = null;

  items = [
    {
      icon: require("@/assets/topic-isletme.svg"),
      title: "İşletme Yönetimi",
      programs: 17,
      courses: 51
    },
    {
      icon: require("@/assets/topic-iletisim.svg"),
      title: "İletişim Bilimleri",
      programs: 13,
      courses: 39
    },
    {
      icon: require("@/assets/topic-kisisel-gelisim.svg"),
      title: "Hobi ve Kişisel Gelişim",
      programs: 6,
      courses: 18
    },
    {
      icon: require("@/assets/topic-saglik.svg"),
      title: "Sağlık ve Bakım Hizmetleri",
      programs: 16,
      courses: 48
    },
    {
      icon: require("@/assets/topic-kurumsal.svg"),
      title: "Kurumsal Protokoller",
      programs: 6,
      courses: 18
    }
  ];
}
